
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapState } from "vuex";

import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";

export default defineComponent({
  components: {
    TabView,
    TabPanel,
    Button,
  },
  computed: {
    ...mapGetters({
      getReceipts: "inventoryModule/getReceipts",
      getActiveTab: "inventoryModule/getActiveTab",
    }),
  },
  mounted() {
    window.addEventListener("resize", this.handleWindowResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
  data: () => ({
    active: 0,
    activeInnerTab: 0,
    windowWidth: window.innerWidth,
    inventoryItems: {
      purchaseOrders: {
        label: "Purchase Orders",
        to: "/purchase-orders/",
      },
    },
  }),
  methods: {
    ...mapActions({
      removeReceipts: "inventoryModule/removeReceipts",
      setActiveTab: "inventoryModule/setActiveTab",
      changeActiveMainTab: "inventoryModule/changeActiveTab",
    }),
    handleWindowResize() {
      this.windowWidth = window.innerWidth;
    },
    handleRemoveReceipts(id: string) {
      this.removeReceipts(id);
      if (this.getReceipts.length === 0) {
        this.setActiveTab(0);
        this.$router.push("/inventory");
      } else {
        this.setActiveTab(1);
      }
    },
    handleMainTabChange(e: any) {
      this.changeActiveMainTab(e.index !== undefined ? e.index : 0);
    },
    handleStatCardTabChange(e: any) {
      this.setActiveTab(e.index);
    },
  },
});
